import React from "react"
import Menu from "../../components/Menu"
import BigNumbers from "../../components/BigNumbers"
import Hero from "../../components/Hero"
import TestBenefits from "../../components/TestBenefits"
import Timeline from "../../components/Timeline"
import CtaContract from "../../components/CtaContract"
import Footer from "../../components/Footer"
import Plans from "../../components/Plans"
import Recommendation from "../../components/Recommendation"

import {
  infoQaHeroQuality,
  infoQaHeroSecurity,
  infoTimelineQA,
  qaTimelineInfoDefault,
  qaTimelineBadges,
  cardsRecommendationQA,
  infoRecommendationQA,
  infoBigNumbersQa,
} from "../../mock/data"

interface Props {
  pageContext: {
    pageName: "design-de-solucoes" | "qualidade-de-software"
  }
}

const PageQA = ({ pageContext: { pageName } }: Props) => {
  return (
    <>
      <Menu pageName={pageName} />
      <Hero {...infoQaHeroQuality} />
      <TestBenefits />
      <Hero {...infoQaHeroSecurity} />
      <Timeline
        infoProps={qaTimelineInfoDefault}
        badges={qaTimelineBadges}
        infoTimeline={infoTimelineQA}
        pageName={pageName}
      />
      <Plans pageName={pageName} />

      <BigNumbers infoBigNumbers={infoBigNumbersQa} pageName={pageName} />
      <Recommendation
        infoCards={cardsRecommendationQA}
        infoRecommendation={infoRecommendationQA}
      />
      <CtaContract pageName={pageName} />
      <Footer />
    </>
  )
}
export default PageQA
