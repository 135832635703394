import React from "react"
import cx from "classnames"

import ServiceCardItem from "../ServiceCardItem"
import Badges from "../Badges"

import {
  cardsTestBenefits,
  BadgesTestBenefits,
  infoTestBenefits,
} from "../../mock/data"

import * as styles from "./styles.module.css"

import { navigate } from "gatsby"

const TestBenefits = () => {
  const containerClass = cx("mx-auto main-container")
  const titleClass = cx(
    "text-white font-bold sm:mb-8 lg:mb-12",
    styles.titleClass
  )

  return (
    <div
      className="bg-blackPearl sm:pt-8 sm:pb-8 lg:pt-16 lg:pb-16"
      id="test-benefits"
    >
      <div className={containerClass}>
        <div className="md:mx-auto md:w-10/12">
          <div className="flex sm:mb-4 lg:mb-6">
            {BadgesTestBenefits.map((item, index) => (
              <div key={index}>
                <Badges icon={item.icon} text={item.text} index={index} />
              </div>
            ))}
          </div>
          <h1
            className={titleClass}
            dangerouslySetInnerHTML={{ __html: infoTestBenefits.title }}
          />
        </div>
        <ServiceCardItem
          infoCard={cardsTestBenefits}
          bigButton
          onClick={() => navigate("/qualidade-de-software/#plans")}
          textButton={infoTestBenefits.textButton}
          smallButton
          textsmallButton={infoTestBenefits.textSmallButton}
        />
      </div>
    </div>
  )
}
export default TestBenefits
